const unixInMilliseconds = ref(null)

useIntervalFn(() => {
  unixInMilliseconds.value += 1000
}, 1000)

const isFetching = ref(false)

const fetchTime = async () => {
  isFetching.value = true

  try {
    const response = await fetch(
      'https://worldtimeapi.org/api/timezone/Europe/Copenhagen'
    )

    const data = await response.json();
    if (!data.unixtime) {
      throw new Error('Invalid response');
    }
    unixInMilliseconds.value = (data.unixtime + data.raw_offset + data.dst_offset) * 1000;
    // console.log(new Date(data.datetime).getTime());
  } catch (error) {
    console.error(error);
  } finally {
    isFetching.value = false;
  }
}

export default () => {
  if (process.client && !unixInMilliseconds.value || !isFetching.value) {
    fetchTime()
  }

  const utcTimestamp = computed(() => {
    const date = new Date(unixInMilliseconds.value);
    let utcHours = date.getUTCHours();
    let utcMinutes = date.getUTCMinutes();
    let utcSeconds = date.getUTCSeconds();

    utcHours = utcHours < 10 ? `0${utcHours}` : utcHours;
    utcMinutes = utcMinutes < 10 ? `0${utcMinutes}` : utcMinutes;
    utcSeconds = utcSeconds < 10 ? `0${utcSeconds}` : utcSeconds;

    return `${utcHours}:${utcMinutes}:${utcSeconds}`
  });
  const utcYear = computed(() => {
    const date = new Date(unixInMilliseconds.value);

    return date.getUTCFullYear()
  });


  return {
    unixInMilliseconds,
    utcTimestamp,
    utcYear,
  }
}
